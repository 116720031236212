@mixin on-large-screen-only($breakpoint: $list_large_breakpoint, $display_mode: block) {
    display: none;

    @media only screen and (min-width: $breakpoint) {
        display: $display_mode;
    }
}

@mixin text($font: $sans_serif, $size: inherit, $weight: 400, $style: unset, $transform: none, $align: unset, $hyphens: none) {
    font-family: $font;
    font-size: $size;
    font-weight: $weight;
    font-style: $style;
    text-transform: $transform;
    text-align: $align;
    hyphens: $hyphens;
}

@mixin truncate-lines($number_of_lines) {
    display: -webkit-box;
    -webkit-line-clamp: $number_of_lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
