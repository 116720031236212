.player {
    background-color: var(--background-color);
    border-top: solid var(--grey) 1px;
    position: fixed;
    padding: 16px 24px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;

    .player-info {
        display: grid;
        align-content: center;
        margin-left: 16px;
        flex-grow: 1;

        .text-info {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .player-info__channel-show {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            strong {
                font-weight: bold;
            }

            @include text($size: 14px, $weight: 300);
        }

        .player-info__title {
            @include text($size: 14px, $weight: 300);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            strong {
                font-weight: bold;
            }
        }

        .player-info__progress-bar {
            @include on-large-screen-only($list_medium_breakpoint, flex);
            margin: 4px 0;

            .start,
            .end {
                @include text($size: 12px, $weight: 400);
            }

            .progress {
                background-color: var(--font-black);
                height: 1.5px;
                width: 100%;
                margin: 0 8px;
                margin-top: 0.33em;
            }
        }
    }

    .player__controls {
        display: flex;
        align-items: center;
        align-content: center;

        .play-button {
            line-height: 0;
            svg {
                width: 24px;
            }
        }

        .volume-controls {
            display: none;

            .icon-volume,
            .icon-mute {
                line-height: 0;
                svg {
                    height: 20px;
                }
            }
            
            .icon-mute svg {
                margin-right: 2.5px;
            }

            input[type="range"].volume-slider {
                opacity: 0;
                transition: opacity .3s ease-in-out;
                display: none;
                width: 100px;
                margin-left: 16px;
                background-color: transparent;
                -webkit-appearance: none;
            }
            input[type="range"].volume-slider:focus {
                outline: none;
            }
            input[type="range"].volume-slider::-webkit-slider-runnable-track {
                background: var(--font-black);
                border: 0px solid var(--font-black);
                border: 0;
                border-radius: 25px;
                width: 100%;
                height: 3px;
                cursor: pointer;
            }
            input[type="range"].volume-slider::-webkit-slider-thumb {
                margin-top: -5px;
                width: 12px;
                height: 12px;
                background: var(--font-black);
                border: 1px solid var(--font-black);
                border-radius: 8px;
                cursor: pointer;
                -webkit-appearance: none;
            }
            input[type="range"].volume-slider:focus::-webkit-slider-runnable-track {
                background: var(--font-black);
            }
            input[type="range"].volume-slider::-moz-range-track {
                background: var(--font-black);
                border: 0px solid var(--font-black);
                border: 0;
                border-radius: 25px;
                width: 100%;
                height: 3px;
                cursor: pointer;
            }
            input[type="range"].volume-slider::-moz-range-thumb {
                width: 12px;
                height: 12px;
                background: var(--font-black);
                border: 1px solid var(--font-black);
                border-radius: 8px;
                cursor: pointer;
            }
            input[type="range"].volume-slider::-ms-track {
                background: transparent;
                border-color: transparent;
                border-width: 7.5px 0;
                color: transparent;
                width: 100%;
                height: 3px;
                cursor: pointer;
            }
            input[type="range"].volume-slider::-ms-fill-lower {
                background: var(--font-black);
                border: 0px solid var(--font-black);
                border: 0;
                border-radius: 50px;
            }
            input[type="range"].volume-slider::-ms-fill-upper {
                background: var(--font-black);
                border: 0px solid var(--font-black);
                border: 0;
                border-radius: 50px;
            }
            input[type="range"].volume-slider::-ms-thumb {
                width: 12px;
                height: 12px;
                background: var(--font-black);
                border: 1px solid var(--font-black);
                border-radius: 8px;
                cursor: pointer;
                margin-top: 0px;
                /*Needed to keep the Edge thumb centred*/
            }
            input[type="range"].volume-slider:focus::-ms-fill-lower {
                background: var(--font-black);
            }
            input[type="range"].volume-slider:focus::-ms-fill-upper {
                background: var(--font-black);
            }
            /*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
            how to remove the virtical space around the range input in IE*/
            @supports (-ms-ime-align: auto) {
                /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
                input[type="range"].volume-slider {
                    margin: 0;
                    /*Edge starts the margin from the thumb, not the track as other browsers do*/
                }
            }

            &:hover  {
                background-color: var(--lightgrey);
                border-radius: 5px;
             
                input[type="range"].volume-slider {
                    display: block;
                    opacity: 1;
                }
            }
        }
    }

    @media only screen and (min-width: $list_medium_breakpoint) {
        padding: 16px 32px;
        .player__controls .volume-controls {
            display: flex;
            align-items: center;
            margin-left: 16px;
            padding: 8px 16px;
        }

        .player-info {
            .player-info__channel-show,
            .player-info__title {
                display: inline;
            }

            .player-info__title::before {
                content: " - ";
            }
        }
    }

    @media only screen and (min-width: $list_big_breakpoint) {
        padding: 16px 64px;

        .player-info {
            .player-info__channel-show,
            .player-info__title {
                font-size: 16px;
            }
        }
    }
}
