.channel-player-container {
    --thumbnail-width: #{$big_thumbnail_width};
    margin: 32px auto;

    .go-back {
        vertical-align: -20%;
        svg {
            height: 24px;
            margin-right: 8px;  
            color: white;
        }
    }

    .current-broadcast-info-container {
        margin: auto;
        
        .current-broadcast-thumbnail-container {
            position: relative;
            width: var(--thumbnail-width);
            margin: auto;
            
            .current-broadcast-thumbnail {
                width: var(--thumbnail-width);
                margin: 32px auto 32px auto;
                display: block;
                box-shadow: 0 4px 5px 1px var(--shadow-color);
                border-radius: 8px;
            }
            
            .play-channel-button-container {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 8px;
                background: linear-gradient(black, transparent 67%);
                opacity: 0;
                transition: opacity .3s ease-in-out;
                
                button {
                    font-size: var(--text-font-size);
                    font-weight: bold;
                    display: block;
                    margin-left: calc(1em - 8px);
                    color: white;
                    padding: 8px 16px;
                    transition: background-color .3s ease-in-out, color .3s ease-in-out;
                    border-radius: 4px;
                    
                    &:first-child {
                        margin-top: calc(1em - 8px);
                    }
                    
                    svg {
                        height: 1em;
                        margin-bottom: -.1em;
                        margin-right: .2em;
                    }

                    path {
                        color: white;
                    }

                    &:hover {
                        background-color: #ffffff22;
                    }
                }

                &:hover,
                &.force-visible {
                    opacity: 1;
                }
            }

        }
    
        .current-broadcast-show {
            @include text($size: 20px, $weight: 300, $transform: uppercase, $align: center);
        }
    
        .current-broadcast-title {
            @include text($size: 20px, $weight: bold, $align: center);
            margin-bottom: 24px;
        }

        .current-broadcast-station {
            @include text($size: 16px, $weight: 300, $align: center, $style: italic);
            margin-bottom: 24px;
        }
    }

    .current-broadcast-summary {
        @include text($font: $sans_serif_condensed, $size: 16px, $weight: 300, $align: justify, $hyphens: auto);
        margin-bottom: 32px;
    }


    .next-broadcast {
        margin-bottom: 8px;
        font-family: $sans_serif_condensed;

        strong {
            font-weight: bold;
        }

        &:not(strong) {
            font-weight: 300;
        }
    }
    
    .go-to-schedule {
        font-size: 15px;
    }

    @media only screen and (min-width: $player_medium_breakpoint) {
        --thumbnail-size: #{$big_thumbnail_width};

        .go-back svg {
            height: 32px;
            margin-right: 16px;
        }

        .current-broadcast-thumbnail {
            margin: 64px auto 32px auto;
        }

        .current-broadcast-show {
            font-size: 24px;
        }
    
        .current-broadcast-title {
            font-size: 24px;
            margin-bottom: 32px;
        }

        .current-broadcast-summary {
            margin-bottom: 48px;
        }

        .go-to-schedule {
            font-size: 14px;
        }

        .next-broadcast {
            margin-bottom: 16px;
        }
    
    }

    @media only screen and (min-width: $player_big_breakpoint) {
        --titles-font-size: 24px;
        --text-font-size: 16px;
        --schedule-button-font-size: 14px;
        
        @media only screen and (min-width: $player_large_breakpoint) {
            --thumbnail-width: #{$large_thumbnail_width};
            --titles-font-size: 32px;
            --text-font-size: 20px;
            --schedule-button-font-size: 18px;
        }

        .current-broadcast-info-container {
            display: flex;
            width: calc(3 * var(--thumbnail-width) + 32px);
            margin: 64px auto 48px auto;

            .current-broadcast-thumbnail-container {
                margin: 0;
            
                .current-broadcast-thumbnail {
                    margin: 0;
                    width: var(--thumbnail-width);
                }
            }

            .main-info {
                margin-left: 32px;
                width: calc(2 * var(--thumbnail-width));

                .current-broadcast-title,
                .current-broadcast-show {
                    text-align: left;
                    font-size: var(--titles-font-size);
                }

                .current-broadcast-station {
                    text-align: left;
                    font-size: var(--text-font-size);
                }

                .current-broadcast-summary {
                    margin-bottom: 0;
                    font-size: var(--text-font-size);
                }
            }
        }

        .channel-info-container {
            width: calc(3 * var(--thumbnail-width) + 32px);
            margin: auto;
        

            .next-broadcast {
                font-size: var(--text-font-size);
            }

            .go-to-schedule {
                font-size: var(--schedule-button-font-size);
            }
        }

    }
}

