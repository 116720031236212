@mixin dark-theme {
    --lightgrey: #444;
    --grey: #555;
    --font-black: #fff;
    --font-white: #000;
    --background-color: #333;
    --shadow-color: #222;
  }

:root.dark {
    @include dark-theme
}
