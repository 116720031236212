.ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.ring:after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--font-black);
    border-color: var(--font-black) transparent var(--font-black) transparent;
    animation: ring 1.2s linear infinite;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}