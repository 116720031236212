.radio-title {
    position: relative;
    overflow: hidden;
    margin: -16px;
    
    .radio-name {
        text-align: center;
        font-size: 24px;
        color: white;
        margin-top: 32px;
        margin-bottom: 48px;
    }

    .ellipse-container {
        overflow: hidden;
    }

    .ellipse {
        position: absolute;
        top: -40px;
        left: -25%;
        background-color: #333;
        width: 150%;
        height: 130px;
        border-radius: 50%;
        z-index: -1;
    }
}

@media only screen and (min-width: $list_medium_breakpoint) {
    .radio-title {
        
        margin: -32px;

        .radio-name {
            margin-top: 48px;
            font-size: 32px;
            text-align: left;
            display: block;
            width: calc(2 * #{$vertical_card_width} + 32px);
            margin-left: auto;
            margin-right: auto;
        }

        .ellipse {
            top: -280px;
            left: -70%;
            width: 200%;
            height: 400px;
        }
    }
}

@media only screen and (min-width: $player_big_breakpoint) {
    .radio-title {
        margin: -48px -32px;
    }
}

@media only screen and (min-width: $list_big_breakpoint) {
    .radio-title .radio-name {
        width: calc(3 * #{$vertical_card_width} + 2 * 32px);
    }
}

@media only screen and (min-width: $list_large_breakpoint) {
    .radio-title .radio-name {
        width: calc(2 * #{$horizontal_card_width} + 32px);
    }
}
