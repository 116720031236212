.radio-logo {
    height: 1.5em;
    vertical-align: -35%;
    margin-right: .35em;
}


@media only screen and (min-width: $list_medium_breakpoint) {
    .channels-list-container {
        width: calc(2 * #{$vertical_card_width} + 32px);
        margin: auto;
    }

    .channels-list-container {
        margin-top: 32px;
    }
}

@media only screen and (min-width: $player_big_breakpoint) {
    .channels-list-container {
        margin-top: 48px;
    }
}

@media only screen and (min-width: $list_big_breakpoint) {
    .channels-list-container {
        width: calc(3 * #{$vertical_card_width} + 2 * 32px);
    }
}

@media only screen and (min-width: $list_large_breakpoint) {
    .channels-list-container {
        width: 1472px;
    }
}
