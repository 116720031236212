.card {
    display: flex;
    background-color: var(--lightgrey);
    border-radius: 4px;
    line-height: 0;
    margin-bottom: 16px;
    
    &:hover {
        background-color: var(--grey);
    }
    
    .thumbnail {
        line-height: 0;
    
        img {
            border-radius: 4px;
            height: 120px;
        }
    }

    .info {
        line-height: 1;
        display: grid;
        align-content: center;
        margin: 0 16px;
        font-size: 14px;


        .show-title {
            font-weight: 300;
            margin-bottom: 4px;
            font-family: $sans_serif_condensed;
        }

        .show-title, .title {
            @include truncate-lines(2)
        }
    }
}


.card--channel {
    .info {
        .channel-name {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 9px;
        }

        .next-title {
            font-weight: 300;
            margin-bottom: 4px;
            font-family: $sans_serif_condensed;
        }        

        .next-title {
            @include truncate-lines(1);
            @include on-large-screen-only($display_mode: -webkit-box);
        }
    }
}


.card--schedule {
    position: relative;
        
    .schedule {
        position: absolute;
        top: 8px;
        //font-weight: bold;
        font-family: $sans_serif_condensed;
        font-size: 14px;
    }

    .info {
        margin-top: calc(1em + 12px);
        flex-grow: 1;
    }
}

/* --------------------------------------------------------------------------------------------- */
/*                                         T A B L E T T E                                       */
/* --------------------------------------------------------------------------------------------- */

@media only screen and (min-width: 736px) {
    .channel-cards {
        display: grid;
        grid-template-columns: repeat(2, $vertical_card_width);
        grid-gap: 32px;
        margin: auto;
    }
    
    .card--channel {
        width: $vertical_card_width;
        height: 480px;
        flex-direction: column;
        border-radius: 8px;
    
        .thumbnail img {
            border-radius: 8px;
            height: $vertical_card_width;
        }
    
        .info {
            margin: 24px 24px;
            font-size: 16px;
            align-content: start;
    
            .channel-name {
                font-size: 24px;
                margin-bottom: 12px;
            }
    
            .show-title, .next-title {
                margin-bottom: 6px;
            }
        }
    }

    .card--schedule { 
        .schedule,
        .info {
            font-size: 16px;
        }

        // .info-wrapper .schedule {
        //     position: absolute;
        //     padding: 6px;
        //     margin-top: 0;
        //     left: 0;
        //     line-height: 1;
        // }
    }
}

@media only screen and (min-width: $list_big_breakpoint) {
    .channel-cards {
        grid-template-columns: repeat(3, $vertical_card_width);
    }
    
    .card--schedule {
        max-width: $list_big_breakpoint;
        margin-left: auto;
        margin-right: auto;
    }
}

/* --------------------------------------------------------------------------------------------- */
/*                                         D E S K T O P                                         */
/* --------------------------------------------------------------------------------------------- */

@media only screen and (min-width: $list_large_breakpoint) {
    .channel-cards {
        grid-template-columns: repeat(2, $horizontal_card_width);
    }
    
    .card--channel {
        width: $horizontal_card_width;
        height: 240px;
        flex-direction: row;
    
        .thumbnail img {
            height: 240px;
        }

        .info .show-title {
            @include truncate-lines(1);
        }

        .title {
            margin-bottom: 24px;
        }
    }
}
