.fadeup-appear,
.fadeup-enter {
    opacity: 0;
    transform: translateY(5px);
}

.fadeup-enter-active,
.fadeup-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease-out,
                transform .3s ease-out;
}

.fadeup-exit {
    opacity: 1;
}

.fadeup-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity .2s ease-in-out,
                transform .2s ease-out;
}

.fadeup-exit-done {
    opacity: 0;
}
