#playlist-wrapper {
    color: white;
    overflow-x: auto;
    margin: 32px auto;

    table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        table-layout: fixed;
        
        // #artist-col { width: 200px }
        #title-col { width: 50%; }

        tr.artist-row{
            display: none;
        }

        td, th {
            padding: .5em 1.5em 0.5em 0.5em;
        }
        
        :not(thead) tr:hover:not(.artist-row) :not(th) {
            background-color: var(--lightgrey);
            white-space: unset !important;
        }

        th,
        .artist-row {
            text-transform: uppercase;
        }

        td, th {
            transition: white-space .4s ease-in-out;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-bottom: solid 1px #666;
            border-top: solid 1px #666;
        }
    }

    input {
        background: inherit;
        width: 100%;
        height: 2em;
        border: 1px solid var(--grey);
        border-radius: .2em;
        padding: 1em;
        font-family: inherit;
        font-size: 16px;
        outline: none;
        margin-bottom: 2em;
        transition: border-color .3s ease-in-out;

        &:focus {
            border-color: var(--font-black);
        }
    }
}


@media screen and (max-width: 1080px) {
    #playlist-wrapper {
        
        table {
            #artist-col { width: 0px; }
            tr.artist-row{
                display: table-row;
                font-weight: bold;
                
                & .artist-cell:not(.first-artist) {
                    padding-top: 3em;
                }
            }
        }
    }
}

