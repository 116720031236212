.badge {
    background-color: var(--lightgrey);
    font-family: "Fira Sans Condensed", 'sans-serif';
    font-weight: 400;
    border-radius: 4px;
    padding: .3em .45em;
    display: inline-block;

}

.badge--inverted {
    background-color: var(--background-color);
    color: var(--font-color);
}

.badge--large-only {
    @include on-large-screen-only($display_mode: inline-block)
}

.button {
    transition: background-color .3s ease-in-out;

    &:hover {
        background-color: var(--lightgrey);
    }
}

.badge.button:hover,
.card.button:hover {
    background-color: var(--grey);
}

.button--text {
    display: inline;
    padding: 8px 16px;
    border-radius: 4px;
}
