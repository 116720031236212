.pagetransi-enter,
.pagetransi-exit {
    position: absolute;
    top: 0;
    left: 0;
}

.pagetransi-appear,
.pagetransi-enter {
    opacity: 0;
}

.pagetransi-enter-active,
.pagetransi-appear-active {
    opacity: 1;
    transition: opacity .3s .3s ease-out;
}

.pagetransi-exit {
    opacity: 1;
}

.pagetransi-exit-active {
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.pagetransi-exit-done {
    opacity: 0;
}
